import React, { useState, useEffect } from 'react';
import './index.css';
import { handleHTML } from '../../../../tool';
import Username from '../../username';

interface QuestionBodyProps {
  views: number;
  text: string;
  askby: string;
  meta: string;
  files?: Array<{ fileType?: string; fileName?: string; file?: string }>; // file is a base64 string
}

// Custom hook to create file URLs from base64 strings
const useQuestionFiles = (
  files: Array<{ fileType?: string; fileName?: string; file?: string }>,
) => {
  const [decodedFiles, setDecodedFiles] = useState<
    Array<{ fileType?: string; fileName?: string; url: string }>
  >([]);

  useEffect(() => {
    const newDecodedFiles = files.map((file, index) => ({
      ...file,
      url: file?.file && file.fileType ? `data:${file.fileType};base64,${file.file}` : '',
    }));

    setDecodedFiles(newDecodedFiles);
  }, [files]);

  return decodedFiles;
};

const QuestionBody = ({ views, text, askby, meta, files = [] }: QuestionBodyProps) => {
  const decodedFiles = useQuestionFiles(files);

  return (
    <div id='questionBody' className='questionBody right_padding'>
      <div className='bold_title answer_question_view'>{views} views</div>

      {/* Content area for text and inline images */}
      <div className='answer_question_content'>
        {/* Display text with HTML formatting */}
        <div className='answer_question_text'>{handleHTML(text)}</div>

        {/* Inline images within the content area */}
        {decodedFiles.length > 0 && (
          <div className='inline_images'>
            {decodedFiles.map((file, index) =>
              file.fileType && file.fileType.startsWith('image/') ? (
                <img
                  key={index}
                  src={file.url}
                  alt={file.fileName || 'Image'}
                  className='inline_image'
                />
              ) : (
                <p key={index}>{file.fileName || 'Unknown file'} (Cannot display this file type)</p>
              ),
            )}
          </div>
        )}
      </div>
      <div className='answer_question_right'>
        <div className='question_author'>
          <Username user={askby}></Username>
        </div>
        <div className='answer_question_meta'>asked {meta}</div>
      </div>
    </div>
  );
};

export default QuestionBody;
