import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useUserContext from './useUserContext';
import { Chat, Message } from '../types';
import { getChatById, sendMessage } from '../services/chatService';
import { decryptSymmetric } from '../services/encryptionService';

/**
 * Custom hook for managing the Chat page state and real-time updates.
 *
 * @returns messages - The list of chats that this user is a part of
 * @returns handleSendMessage - Handles sending a message in the chat
 * @returns members - List of members of the chat room
 */
const useChatPage = () => {
  const { socket } = useUserContext();
  const [messages, setMessages] = useState<Message[]>([]);
  const [members, setMembers] = useState<string[]>([]);
  const { cid } = useParams();

  const handleSendMessage = async (message: Message) => {
    await sendMessage(cid as string, message);
  };

  useEffect(() => {
    /**
     * Function to fetch the chats that this user is a member of.
     */
    const fetchData = async () => {
      try {
        const res: Chat = await getChatById(cid as string);
        setMessages(res.messages || []);
        setMembers(res.members || []);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    /**
     * Function to handle chatCreated updates from the socket.
     * Displays the chat if the currently logged in user is a member
     *
     * @param message - New message
     */
    const handleMessage = async (message: Message) => {
      message.text = await decryptSymmetric(
        message.text,
        message.iv!,
        process.env.REACT_APP_GLOBAL_KEY as string,
      );
      setMessages(m => [...m, message]);
    };

    socket.on('messageUpdate', handleMessage);
    socket.emit('subscribe', cid as string);

    fetchData();

    return () => {
      // verify that this works as intended
      socket.emit('unsubscribe', cid as string);
    };
  }, [cid, socket]);

  return { messages, members, handleSendMessage };
};

export default useChatPage;
