import React from 'react';
import './index.css';

/**
 * Interface representing the props for the username component.
 *
 * - user - The name of the user to display in the username.
 */
interface UsernameProps {
  user: string;
}

/**
 * Username component that displays a user for consistency
 *
 * @param user The name of the user to display in the username
 */
const Username = ({ user }: UsernameProps) => <a href={`/profile/${user}`}>{user}</a>;

export default Username;
