import React from 'react';
import useHeader from '../../hooks/useHeader';
import './index.css';
import useUserContext from '../../hooks/useUserContext';

/**
 * Header component that renders the main title and a search bar.
 * The search bar allows the user to input a query and navigate to the search results page
 * when they press Enter.
 */
const Header = () => {
  const { val, handleInputChange, handleKeyDown, handleLogin, handleLogout, handleProfileClick } =
    useHeader();
  const { user } = useUserContext();

  return (
    <div id='header' className='header'>
      <div className='spacer'></div>
      <div className='title'>
        <img src='/segfaultlogo.png' alt='Seg Fault Logo' className='headerLogo' />
        Seg Fault
      </div>
      <div className='headerUtilities right_padding'>
        <input
          id='searchBar'
          className='searchBar'
          placeholder='Search ...'
          type='text'
          value={val}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {user.username ? (
          <div>
            <div className='headerButton'>
              <button className='bluebtn' onClick={handleProfileClick}>
                Profile
              </button>
            </div>
            <div className='headerButton'>
              <button className='bluebtn' onClick={handleLogout}>
                Log Out
              </button>
            </div>
          </div>
        ) : (
          <button className='bluebtn' onClick={handleLogin}>
            Log In
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
