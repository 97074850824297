import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNewQuestion from '../../../hooks/useNewQuestion';
import Form from '../baseComponents/form';
import Input from '../baseComponents/input';
import './index.css';
import TextEditorBox from '../textEditorBox';
import { getQuestionById } from '../../../services/questionService';

const NewQuestionPage = () => {
  const { qid } = useParams<{ qid: string }>();
  const {
    title,
    setTitle,
    text,
    setText,
    tagNames,
    setTagNames,
    titleErr,
    textErr,
    tagErr,
    saveQuestion,
    handleFileChange,
    flags,
    setFlags,
  } = useNewQuestion(qid);

  const [editorKey, setEditorKey] = useState(qid || 'new');

  useEffect(() => {
    const fetchDraftOrQuestion = async () => {
      if (qid) {
        try {
          const draft = await getQuestionById(qid, '');
          setTitle(draft.title || '');
          setText(draft.text || '');
          const tagNamesString = draft.tags?.map(tag => tag.name).join(' ') || '';
          setTagNames(tagNamesString);
          setFlags(draft.flags || []);
          setEditorKey(`${qid}-${Date.now()}`);
        } catch (error) {
          throw new Error("Couldn't fetch draft");
        }
      }
    };
    fetchDraftOrQuestion();
  }, [qid, setTitle, setText, setTagNames, setFlags]);

  const handleFlagChange = (event: React.ChangeEvent<HTMLInputElement>, flag: string) => {
    const isChecked = event.target.checked;
    setFlags(prevFlags =>
      isChecked ? [...prevFlags, flag] : prevFlags.filter(existingFlag => existingFlag !== flag),
    );
  };

  return (
    <Form>
      <Input
        title={'Question Title'}
        hint={'Limit title to 100 characters or less'}
        id={'formTitleInput'}
        val={title}
        setState={setTitle}
        err={titleErr}
      />
      <TextEditorBox
        key={editorKey}
        title={'Question Text'}
        hint={'Add details'}
        val={text}
        setState={setText}
        err={textErr}
      />
      <Input
        title={'Tags'}
        hint={'Add keywords separated by whitespace'}
        id={'formTagInput'}
        val={tagNames}
        setState={setTagNames}
        err={tagErr}
      />
      <div>
        <label htmlFor='fileInput' className='fileInputLabel'></label>
        <input type='file' id='fileInput' multiple onChange={handleFileChange} />
      </div>
      <div>
        <input
          type='checkbox'
          id='draftCheckbox'
          checked={flags.includes('D')}
          onChange={e => handleFlagChange(e, 'D')}
        />
        <label htmlFor='draftCheckbox'>Draft</label>
      </div>
      <div>
        <input
          type='checkbox'
          id='urgentCheckbox'
          checked={flags.includes('U')}
          onChange={e => handleFlagChange(e, 'U')}
        />
        <label htmlFor='urgentCheckbox'>Urgent</label>
      </div>
      <div className='btn_indicator_container'>
        <button
          className='form_postBtn'
          onClick={e => {
            e.preventDefault();
            saveQuestion();
          }}>
          Post Question
        </button>
        <div className='mandatory_indicator'>* indicates mandatory fields</div>
      </div>
    </Form>
  );
};

export default NewQuestionPage;
