import React from 'react';

/**
 * List of all the months of the year.
 */
const MONTHS: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Helper function to format the day of the month with leading zero if necessary.
 * It returns a string representing the day of the month with a leading zero if it's less than 10.
 *
 * @param date - The date object from which to extract the day.
 */
const getDateHelper = (date: Date): string => {
  const day = date.getDate();
  if (day < 10) {
    return `0${day}`;
  }
  return `${day}`;
};

/**
 * Function to get a human-readable metadata string representing the time difference
 * between now and the given date.
 *
 * @param date - The date object to compare with the current date.
 */
const getMetaData = (date: Date): string => {
  const now = new Date();
  const diffs = Math.floor(Math.abs(now.getTime() - date.getTime()) / 1000);

  if (diffs < 60) {
    return `${diffs} seconds ago`;
  }
  if (diffs < 60 * 60) {
    return `${Math.floor(diffs / 60)} minutes ago`;
  }
  if (diffs < 60 * 60 * 24) {
    const h = Math.floor(diffs / 3600);
    return `${h} hours ago`;
  }
  if (diffs < 60 * 60 * 24 * 365) {
    return `${MONTHS[date.getMonth()]} ${getDateHelper(date)} at ${date
      .toTimeString()
      .slice(0, 8)}`;
  }
  return `${MONTHS[date.getMonth()]} ${getDateHelper(
    date,
  )}, ${date.getFullYear()} at ${date.toTimeString().slice(0, 8)}`;
};

/**
 * Function to convert the HTML string to actual HTML and return
 * NOTE: only use with already santized input (tiptap editor auto-sanitizes input)
 *
 * @param text - The text containing html in plaintext format
 */
const handleHTML = (text: string) => <div dangerouslySetInnerHTML={{ __html: text }} />;

export { getMetaData, handleHTML };
