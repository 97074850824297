import './index.css';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useInboxPage from '../../../hooks/useInboxPage';
import useUserContext from '../../../hooks/useUserContext';
import { createChat } from '../../../services/chatService';

/**
 * InboxPage component renders a page displaying a list of user chats.
 * It includes an option to create new chats.
 */
const InboxPage = () => {
  const { chatList } = useInboxPage();
  const { user } = useUserContext();
  const [text, setText] = useState<string>('');
  const [textErr, setTextErr] = useState<string>('');
  const navigate = useNavigate();

  /**
   * Handler for when a chat is clicked.
   * Navigates to the chat of that ID.
   * @param id chat ID
   */
  const handleChatClick = (id: string) => {
    if (id) {
      navigate(`/chat/id/${id}`);
    } else {
      throw new Error('Chat does not have a valid ID!');
    }
  };

  /**
   * Creates a new chat with a list of members provided in the text field.
   */
  const handleCreateChat = async () => {
    const usernames = text.split(' ').filter(u => u !== '');
    try {
      setTextErr('');
      if (user.username) {
        if (!usernames.includes(user.username)) {
          usernames.push(user.username);
        }
        if (usernames.length >= 2) {
          await createChat(usernames);
        }
      }
    } catch (e) {
      setTextErr((e as AxiosError).response!.data as string);
    }
  };

  return (
    <div id='inbox_container'>
      <div className='new-chat'>
        <div className='input-row'>
          <textarea
            placeholder='Username(s)'
            value={text}
            onChange={e => setText(e.target.value)}
            className='message-textarea'
          />
          <button className='create-chat-button' onClick={handleCreateChat}>
            Create chat
          </button>
        </div>
        {textErr && <small className='error-inbox'>{textErr}</small>}
      </div>
      <div id='chat_list' className='chat_list'>
        {chatList.map((c, idx) => (
          <div className='chat' onClick={() => handleChatClick(c._id ?? '')} key={idx}>
            <h3>Chat with: {c.members.filter(m => m !== user.username).join(', ')}</h3>
            <span>{c.messages.length} messages.</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InboxPage;
