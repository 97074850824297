import { useEffect, useState } from 'react';
import useUserContext from './useUserContext';
import { Chat } from '../types';
import { getChatsByUser } from '../services/chatService';

/**
 * Custom hook for managing the Inbox page state and real-time updates.
 *
 * @returns chatList - The list of chats that this user is a part of
 */
const useInboxPage = () => {
  const { socket, user } = useUserContext();
  const [chatList, setChatList] = useState<Chat[]>([]);

  useEffect(() => {
    /**
     * Function to fetch the chats that this user is a member of.
     */
    const fetchData = async () => {
      try {
        if (!user.username) {
          throw new Error('Username undefined.');
        }
        const res = await getChatsByUser(user.username);
        setChatList(res || []);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    /**
     * Function to handle chatCreated updates from the socket.
     * Displays the chat if the currently logged in user is a member
     *
     * @param chat - Newly created chat
     */
    const handleChatCreated = (chat: Chat) => {
      if (user.username && chat.members.includes(user.username)) {
        setChatList(c => [...c, chat]);
      }
    };

    socket.on('chatCreated', handleChatCreated);

    fetchData();
  }, [socket, user.username]);

  return { chatList };
};

export default useInboxPage;
