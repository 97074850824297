import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';
import Form from '../main/baseComponents/form';
import Input from '../main/baseComponents/input';

/**
 * Login Component contains a form that allows the user to input their login information, which is used to authenticate
 * the user and log them into the application through the useLogin hook.
 */
const Login = () => {
  const {
    username,
    password,
    usernameError,
    passwordError,
    formError,
    setUsername,
    setPassword,
    handleSubmit,
  } = useLogin();

  return (
    <div className='container'>
      <h2>Login</h2>
      <div className='input-form-error'>{formError}</div>
      <div className='form-container'>
        <Form>
          <Input
            title='Username'
            id='usernameInput'
            val={username}
            setState={setUsername}
            err={usernameError}
          />
          <Input
            title='Password'
            id='passwordInput'
            type='password'
            val={password}
            setState={setPassword}
            err={passwordError}
          />
          <button type='submit' className='login-button' onClick={handleSubmit}>
            Submit
          </button>
        </Form>
      </div>
      <span>
        Don&apos;t have an account?{' '}
        <Link to={'/register'} className='login-register-link'>
          Register here
        </Link>
      </span>
      <Link to={`${process.env.REACT_APP_SERVER_URL}/auth/google`} className='social-login-link'>
        <img src='/google.png' alt='Google Logo' className='social-login-image' />
        Login with Google
      </Link>
      <Link to={`${process.env.REACT_APP_SERVER_URL}/auth/github`} className='social-login-link'>
        <img src='/github.png' alt='GitHub Logo' className='social-login-image' />
        Login with GitHub
      </Link>
    </div>
  );
};

export default Login;
