import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Chat, UploadedFile, Question, User } from '../types';
import useUserContext from './useUserContext';
import { getQuestionsByFilter } from '../services/questionService';
import { editBio, editPic, getUserByUsername } from '../services/userService';
import { getFileById } from '../services/fileService';
import { createChat } from '../services/chatService';

/**
 * Custom hook for managing the profile page's state
 *
 * @returns profileUser: the user the profile is for
 */
const useProfilePage = () => {
  const { un } = useParams();
  // console.log("Username = " + un)
  const { user } = useUserContext();
  const [username, setUsername] = useState<string>(un || '');
  const [profileUser, setProfileUser] = useState<User | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newBio, setNewBio] = useState(user.bio);
  const [picURL, setPicURL] = useState<string>('');
  const [qlist, setQlist] = useState<Question[]>([]);
  const [startChatErr, setStartChatErr] = useState<string>('');
  const navigate = useNavigate();

  // Handle edit mode toggle
  const handleEditClick = () => setIsEditing(true);
  const handleCancelClick = () => setIsEditing(false);
  const maxBioLength = 500;
  const isUser = user.username === username;
  // Save the new bio and exit edit mode
  const handleSaveClick = () => {
    if (user.username) {
      setProfileUser({ ...user, bio: newBio });
      editBio(user.username, newBio);
    }

    setIsEditing(false);
  };

  // Update the bio with a character limit
  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= maxBioLength) {
      setNewBio(e.target.value);
    }
  };

  /**
   * Decodes an uploaded file from base64
   * @param uploadedFile: the file to decode
   * @returns file: the created blob from the base64 UploadedFile
   */
  const decodeUploadedFile = (uploadedFile: UploadedFile) => {
    // Decode the base64 string
    const byteString = atob(uploadedFile.file);
    const mimeType = uploadedFile.fileType;

    // Convert byteString to an array buffer
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    // Create a Blob and then a File
    const blob = new Blob([byteArray], { type: mimeType });
    const file = new File([blob], uploadedFile.fileName, {
      type: mimeType,
      lastModified: new Date(uploadedFile.fileDateTime).getTime(),
    });

    return file;
  };
  /**
   * Handles changing a picture when a user uploads a new profile pic
   */
  const handlePicChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      const processImageToSquare = async (imFile: File, targetSize: number): Promise<string> => {
        const img = new Image();
        const reader = new FileReader();

        // Convert file to data URL
        const dataUrl = await new Promise<string>((resolve, reject) => {
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(imFile);
        });

        img.src = dataUrl;

        return new Promise<string>((resolve, reject) => {
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
              reject(new Error('Failed to get canvas context'));
              return;
            }

            // Set canvas size for the target square
            canvas.width = targetSize;
            canvas.height = targetSize;

            // Ensure a transparent background
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Calculate cropping dimensions to center the image
            const aspectRatio = img.width / img.height;
            let sourceX;
            let sourceY;
            let sourceWidth;
            let sourceHeight;

            if (aspectRatio > 1) {
              // Wider image: crop the sides
              sourceWidth = img.height;
              sourceHeight = img.height;
              sourceX = (img.width - img.height) / 2;
              sourceY = 0;
            } else {
              // Taller image: crop the top and bottom
              sourceWidth = img.width;
              sourceHeight = img.width;
              sourceX = 0;
              sourceY = (img.height - img.width) / 2;
            }

            // Draw the cropped and scaled image to the square canvas
            ctx.drawImage(
              img,
              sourceX,
              sourceY,
              sourceWidth,
              sourceHeight,
              0,
              0,
              targetSize,
              targetSize,
            );

            // Export canvas to Base64
            resolve(canvas.toDataURL(imFile.type));
          };

          img.onerror = err => reject(err);
        });
      };

      // Process the image
      const downscaledImageBase64 = await processImageToSquare(file, 256);

      const uploadedPic = {
        fileType: file.type,
        fileName: file.name,
        file: downscaledImageBase64.split(',')[1],
        size: file.size,
        fileBy: user.username || '',
        fileDateTime: new Date(),
      };
      setPicURL(URL.createObjectURL(decodeUploadedFile(uploadedPic)));
      if (user.username) {
        const fid = await editPic(user.username, uploadedPic);
        setProfileUser({ ...user, profilePicId: fid });
      }
    }
  };

  // starts a chat with this user, if possible
  // will not start a chat if not signed in or if the users are the same, or if the chat already exists
  const handleStartChat = async () => {
    try {
      if (user.username && username && user.username !== username) {
        const chat: Chat = await createChat([user.username, username]);
        if (chat) {
          // success - navigate to the correct chat page
          setStartChatErr('');
          navigate(`/chat/id/${chat._id}`);
        }
        setStartChatErr('Error starting chat.');
      }
    } catch (error) {
      setStartChatErr('Error starting chat.');
    }
  };

  useEffect(() => {
    /**
     * Function to fetch the user data based on the username.
     */
    const fetchData = async () => {
      try {
        if (user.username && username === '') {
          setUsername(user.username);
        } else {
          const res = await getUserByUsername(username);
          setProfileUser(res || null);
          const qres = await getQuestionsByFilter('newest', '', username);
          setQlist(qres || []);
          if (res.profilePicId) {
            const pfpres = await getFileById(res.profilePicId);
            setPicURL(URL.createObjectURL(decodeUploadedFile(pfpres)));
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error getting user ${username}:`, error);
      }
    };

    // eslint-disable-next-line no-console
    fetchData().catch(e => console.log(e));
  }, [user.username, username]);

  return {
    profileUser,
    isEditing,
    isUser,
    newBio,
    maxBioLength,
    qlist,
    picURL,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleBioChange,
    handleStartChat,
    startChatErr,
    handlePicChange,
  };
};

export default useProfilePage;
