import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useLoginContext from './useLoginContext';
import { getUserByUsername, login } from '../services/userService';

/**
 * Custom hook to handle login input and submission.
 *
 * @returns username - The current value of the username input.
 * @returns password - The current value of the password input.
 * @returns usernameError - The error message for the username input.
 * @returns passwordError - The error message for the password input.
 * @returns formError - The error message for the entire form.
 * @returns setUsername - Function to set the username input.
 * @returns setPassword - Function to set the password input.
 * @returns handleSubmit - Function to handle the login form submission.
 */
const useLogin = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [formError, setFormError] = useState<string>('');
  const { setUser } = useLoginContext();
  const navigate = useNavigate();

  /**
   * Function to validate the login form.
   *
   * @returns boolean - True if the form is valid, false otherwise.
   */
  const validateForm = (): boolean => {
    let isValid = true;
    if (!username) {
      setUsernameError('Username cannot be empty');
      isValid = false;
    } else {
      setUsernameError('');
    }
    if (!password) {
      setPasswordError('Password cannot be empty');
      isValid = false;
    } else {
      setPasswordError('');
    }

    return isValid;
  };

  /**
   * Function to handle the login form submission.
   */
  const handleSubmit = async () => {
    setFormError('');
    if (!validateForm()) return;

    try {
      const loginUsername = await login(username, password);
      const loginUser = await getUserByUsername(loginUsername);
      setUser(loginUser);
      navigate('/home');
    } catch (error) {
      if (error instanceof Error && error.message) {
        setFormError(error.message);
      } else {
        setFormError('Error while logging in');
      }
    }
  };

  return {
    username,
    password,
    usernameError,
    passwordError,
    formError,
    setUsername,
    setPassword,
    handleSubmit,
  };
};

export default useLogin;
