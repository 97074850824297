import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle, { TextStyleOptions } from '@tiptap/extension-text-style';
import { Editor, EditorProvider, ReactNodeViewRenderer, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import './index.css';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { all, createLowlight } from 'lowlight';
import hljs from 'highlight.js';
import { useEffect, useRef, useState } from 'react';
import CodeBlockComponent from './codeBlockComponent';

/**
 * Interface representing the props for the TextEditorBox component.
 *
 * - title - The label to display
 * - mandatory - Indicates whether the field is required, it's optional.
 * - hint - An optional helper text providing additional information.
 * - extensions - The list of extensions to use in the RTE box, optional
 * - val - The current value of the input field.
 * - setState - Callback function to update the state with the input field's value.
 * - err - An error message displayed if there's an issue with the input.
 */
interface TextEditorBoxProps {
  title: string;
  mandatory?: boolean;
  hint?: string;
  val: string;
  setState: (value: string) => void;
  err?: string;
}

const lowlight = createLowlight(all);

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className='control-group'>
      <div className='button-group'>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
          title='Bold'>
          <i className='ri-bold'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
          title='Italic'>
          <i className='ri-italic'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
          title='Strikethrough'>
          <i className='ri-strikethrough'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
          title='Clear formatting'>
          <i className='ri-format-clear'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
          title='Paragraph'>
          <i className='ri-paragraph'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
          title='Heading'>
          <i className='ri-h-1'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
          title='Bullet list'>
          <i className='ri-list-unordered'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
          title='Ordered list'>
          <i className='ri-list-ordered'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
          title='Code block'>
          <i className='ri-code-box-line'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
          title='Blockquote'>
          <i className='ri-double-quotes-l'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          title='Horizontal rule'>
          <i className='ri-separator'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          title='Undo'>
          <i className='ri-arrow-go-back-line'></i>
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          title='Redo'>
          <i className='ri-arrow-go-forward-line'></i>
        </button>
      </div>
    </div>
  );
};

const EXTENSIONS = [
  CodeBlockLowlight.extend({
    addNodeView() {
      return ReactNodeViewRenderer(CodeBlockComponent);
    },
  }).configure({ lowlight }),
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] } as Partial<TextStyleOptions>),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

/**
 * TextEditorBox component renders a customizable input with optional title, hint,
 * error message, and mandatory indication.
 *
 * @param title - The label of the textarea.
 * @param mandatory - Indicates whether the textarea is mandatory. Default is true.
 * @param hint - Optional text providing additional instructions.
 * @param id - The unique identifier of the textarea element.
 * @param val - The current value of the textarea.
 * @param setState - The function to update the state of the textarea value.
 * @param err - Optional error message displayed when there's an issue with input.
 */

const TextEditorBox = ({
  title,
  mandatory = true,
  hint,
  val,
  err,
  setState,
}: TextEditorBoxProps) => {
  const [initialContent, setInitialContent] = useState(val); // Local state for initial content
  const editorRef = useRef<Editor | null>(null);

  useEffect(() => {
    if (val !== initialContent) {
      setInitialContent(val);
    }
    if (editorRef.current) {
      editorRef.current.commands.setContent(val); // Explicitly update content
    }
    // Update local state whenever `val` changes (e.g., when fetching a draft)
    setInitialContent(val);
  }, [val, initialContent]);

  const highlightContent = (content: string) => {
    // Dynamically create a temporary container to hold the HTML for highlighting
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = content;

    // Apply Highlight.js to all code blocks within the temporary container
    tempContainer.querySelectorAll('pre code').forEach(block => {
      hljs.highlightElement(block as HTMLElement);
    });

    // Return the highlighted HTML as a string
    return tempContainer.innerHTML;
  };

  return (
    <>
      <div className='input_title'>{title + (mandatory ? '*' : '')}</div>
      {hint && <div className='input_hint'>{hint}</div>}
      {/* Debugging `initialContent` */}
      <EditorProvider
        slotBefore={<MenuBar />}
        extensions={EXTENSIONS}
        content={val}
        onUpdate={({ editor }) => {
          const htmlContent = editor.getHTML();

          // Highlight the content
          const highlightedContent = highlightContent(htmlContent);

          // Pass the highlighted content to the parent state
          setState(highlightedContent);
        }}
      />
      {err && <div className='input_error'>{err}</div>}
    </>
  );
};

export default TextEditorBox;
