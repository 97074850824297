import axios from 'axios';
import api from './config';
import { Profile, UploadedFile, User } from '../types';

const USER_API_URL = `${process.env.REACT_APP_SERVER_URL}/user`;
const AUTH_API_URL = `${process.env.REACT_APP_SERVER_URL}/auth`;

/**
 * Function to log in a user.
 *
 * @param username - The username of the user.
 * @param password - The password of the user.
 *
 * @returns The username of the logged-in user.
 * @throws Error if there is an issue logging in the user.
 */
const login = async (username: string, password: string): Promise<string> => {
  let res;
  try {
    res = await api.post(`${USER_API_URL}/login`, { username, password });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      throw new Error(error.response.data);
    } else {
      throw new Error('Error while logging in');
    }
  }
  if (res.status !== 200) {
    throw new Error('Error while logging in');
  }
  return res.data.username;
};

/**
 * Function to register a new user.
 *
 * @param user - The user object to register.
 *
 * @returns The username of the registered user.
 * @throws Error if there is an issue registering the user.
 */
const register = async (user: {
  username: string;
  password: string;
  provider: string;
  providerId: string;
  email: string;
  firstName: string;
  lastName: string;
}): Promise<string> => {
  let res;
  try {
    res = await api.post(`${USER_API_URL}/register`, user);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      throw new Error(error.response.data);
    } else {
      throw new Error('Error while registering');
    }
  }
  if (res.status !== 200) {
    throw new Error('Error while registering');
  }
  return res.data.username;
};

/**
 * Function to log out a user.
 *
 * @throws Error if there is an issue logging out the user.
 */
const logout = async (): Promise<void> => {
  const res = await api.post(`${USER_API_URL}/logout`);

  if (res.status !== 200) {
    throw new Error('Error while logging out');
  }
};

/**
 * Function to get the current user.
 *
 * @returns The username of the current user.
 * @throws Error if there is an issue fetching the current user.
 */
const getCurrentUser = async (): Promise<string> => {
  const res = await api.get(`${USER_API_URL}/getCurrentUser`);

  if (res.status !== 200) {
    throw new Error('Error while fetching current user');
  }

  return res.data.username;
};

/**
 * Function to get the social login profile of the current user.
 *
 * @returns The social login profile of the current user.
 * @throws Error if there is an issue fetching the profile.
 */
const getProfile = async (): Promise<Profile> => {
  const res = await api.get(`${AUTH_API_URL}/getProfile`);

  if (res.status !== 200) {
    throw new Error('Error while fetching profile');
  }

  return res.data;
};

/**
 * Function to destroy the social login profile of the current user.
 *
 * @throws Error if there is an issue destroying the profile.
 */
const destroyProfile = async (): Promise<void> => {
  const res = await api.get(`${AUTH_API_URL}/destroyProfile`);

  if (res.status !== 200) {
    throw new Error('Error while destroying profile');
  }
};

/**
 * Function to get the data of a user by username.
 *
 * @returns A User type with the user's data.
 * @throws Error if there is an issue fetching the user.
 */
const getUserByUsername = async (username: string): Promise<User> => {
  const res = await api.get(`${USER_API_URL}/getUserByUsername/${username}`);
  if (res.status !== 200) {
    throw new Error('Error when fetching user by username');
  }
  return res.data;
};

/**
 * Edits a user's bio
 *
 * @param username - The username of the user to edit.
 * @param bio - A string containing the new bio
 * @throws Error Throws an error if the request fails or the response status is not 200.
 */
const editBio = async (username: string, bio: string | undefined): Promise<string> => {
  const data = { username, bio };

  const res = await api.post(`${USER_API_URL}/editBio`, data);
  if (res.status !== 200) {
    throw new Error('Error while editing a user bio');
  }
  return res.data;
};

/**
 * Edits a user's profile pic
 *
 * @param username - The username of the user to edit.
 * @param pic - An uploaded file containing a new pic
 * @throws Error Throws an error if the request fails or the response status is not 200.
 */
const editPic = async (username: string, pic: UploadedFile): Promise<string> => {
  const data = { username, pic };

  const res = await api.post(`${USER_API_URL}/editPic`, data);
  if (res.status !== 200) {
    throw new Error('Error while editing a user pic');
  }
  return res.data;
};

export {
  login,
  register,
  logout,
  getCurrentUser,
  getProfile,
  destroyProfile,
  getUserByUsername,
  editBio,
  editPic,
};
