// eslint-disable-next-line
const Buffer = require('buffer/').Buffer;

/**
 * Encrypts a string symmetrically with a given key.
 *
 * @param plaintext The string to encrypt.
 * @param key The key to use for encryption.
 *
 * @returns An object containing the ciphertext and the initialization vector in base64 format.
 */
export const encryptSymmetric = async (plaintext: string, key: string) => {
  const iv = crypto.getRandomValues(new Uint8Array(12));

  const encoded = new TextEncoder().encode(plaintext);

  const keyAES = await window.crypto.subtle.importKey(
    'raw',
    Buffer.from(key, 'base64'),
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const ciphertext = await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    keyAES,
    encoded,
  );

  return {
    ciphertext: Buffer.from(ciphertext).toString('base64'),
    iv: Buffer.from(iv).toString('base64'),
  };
};

/**
 * Decrypts a ciphertext symmetrically with a given key.
 *
 * @param ciphertext The ciphertext to decrypt, in base64 format.
 * @param iv The initialization vector used for encryption, in base64 format.
 * @param key The key to use for decryption, in base64 format.
 *
 * @returns The decrypted plaintext as a string.
 */
export const decryptSymmetric = async (ciphertext: string, iv: string, key: string) => {
  const keyAES = await window.crypto.subtle.importKey(
    'raw',
    Buffer.from(key, 'base64'),
    {
      name: 'AES-GCM',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const text = await window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: Buffer.from(iv, 'base64'),
    },
    keyAES,
    Buffer.from(ciphertext, 'base64'),
  );

  return new TextDecoder().decode(text);
};
