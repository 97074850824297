import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoginContext from './useLoginContext';
import { destroyProfile, getProfile, getUserByUsername, register } from '../services/userService';

/**
 * Interface representing the different fields of the registration form.
 *
 * - username - The username of the user.
 * - password - The password of the user.
 * - confirmPassword - The confirmation of the password.
 * - email - The email of the user.
 * - firstName - The first name of the user.
 * - lastName - The last name of the user.
 */
export interface RegistrationForm {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  firstName: string;
  lastName: string;
}

/**
 * Interface representing the different error messages for the registration form fields.
 *
 * - username - The error message for the username field.
 * - password - The error message for the password field.
 * - confirmPassword - The error message for the confirm password field.
 * - email - The error message for the email field.
 */
interface RegistrationError {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
}

/**
 * Interface representing which fields are read-only in the registration form.
 */
interface RegistrationReadOnly {
  email: boolean;
  firstName: boolean;
  lastName: boolean;
}

const noWhitespaceRegex = /^\S+$/;
const lowercaseRegex = /[a-z]+/;
const uppercaseRegex = /[A-Z]+/;
const numberRegex = /[0-9]+/;

// This regex comes from https://emailregex.com/. It is not custom written.
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Custom hook to handle registration submission and form validation.
 *
 * @returns registrationForm - The current values of the registration form fields.
 * @returns registrationError - The error messages for the registration form fields.
 * @returns registrationReadOnly - The boolean values for which fields are read-only in the registration form.
 * @returns formError - Error message for the form, if any.
 * @returns isSsoRegistration - Whether the registration is through a single sign-on provider.
 * @returns setRegistrationForm - Function to update the registration form fields.
 * @returns handleSubmit - Function to validate the form and submit the registration.
 */
const useRegister = () => {
  const [registrationForm, setRegistrationForm] = useState<RegistrationForm>({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    firstName: '',
    lastName: '',
  });
  const [registrationError, setRegistrationError] = useState<RegistrationError>({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
  });
  const [registrationReadOnly, setRegistrationReadOnly] = useState<RegistrationReadOnly>({
    email: false,
    firstName: false,
    lastName: false,
  });
  const [provider, setProvider] = useState('');
  const [providerId, setProviderId] = useState('');
  const [formError, setFormError] = useState('');
  const { setUser } = useLoginContext();
  const navigate = useNavigate();
  const [isSsoRegistration, setIsSsoRegistration] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getProfile();
        if (res && res.provider && res.providerId) {
          setProvider(res.provider);
          setProviderId(res.providerId);
          setIsSsoRegistration(true);
          if (res.firstName) {
            setRegistrationForm(regForm => ({ ...regForm, firstName: res.firstName }));
            setRegistrationReadOnly(regReadOnly => ({ ...regReadOnly, firstName: true }));
          }
          if (res.lastName) {
            setRegistrationForm(regForm => ({ ...regForm, lastName: res.lastName }));
            setRegistrationReadOnly(regReadOnly => ({ ...regReadOnly, lastName: true }));
          }
          if (res.email) {
            setRegistrationForm(regForm => ({ ...regForm, email: res.email }));
            setRegistrationReadOnly(regReadOnly => ({ ...regReadOnly, email: true }));
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error while fetching profile:', error);
      }
    };
    fetchData();

    return () => {
      destroyProfile();
    };
  }, []);

  /**
   * Function to validate certain constraints on the registration form fields.
   *
   * @param isValid - The current validity of the form.
   * @param predicate - The condition to check.
   * @param field - The field to update in case of an error.
   * @param errorMessage - The error message to display.
   *
   * @returns boolean - The updated validity of the form.
   */
  const validateConstraint = (
    isValid: boolean,
    predicate: () => boolean,
    field: string,
    errorMessage: string,
  ): boolean => {
    if (!predicate()) {
      setRegistrationError(regError => ({
        ...regError,
        [field]: errorMessage,
      }));
      return false;
    }
    setRegistrationError(regError => ({ ...regError, [field]: '' }));
    return isValid;
  };

  /**
   * Function to validate the registration form before submitting.
   *
   * @returns boolean - True if the form is valid, false otherwise.
   */
  const validateForm = (): boolean => {
    let isValid = true;

    isValid = validateConstraint(
      isValid,
      () => !!registrationForm.username,
      'username',
      'Username cannot be empty',
    );
    isValid = validateConstraint(
      isValid,
      () => noWhitespaceRegex.test(registrationForm.username),
      'username',
      'Username cannot contain any spaces',
    );
    if (!isSsoRegistration) {
      isValid = validateConstraint(
        isValid,
        () => !!registrationForm.password,
        'password',
        'Password cannot be empty',
      );
      isValid = validateConstraint(
        isValid,
        () =>
          registrationForm.password.length >= 8 &&
          lowercaseRegex.test(registrationForm.password) &&
          uppercaseRegex.test(registrationForm.password) &&
          numberRegex.test(registrationForm.password),
        'password',
        'Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase ' +
          'letter, and one number.',
      );
      if (registrationForm.password || registrationForm.confirmPassword) {
        isValid = validateConstraint(
          isValid,
          () => registrationForm.password === registrationForm.confirmPassword,
          'confirmPassword',
          'Passwords do not match',
        );
      }
    }
    if (registrationForm.email) {
      isValid = validateConstraint(
        isValid,
        () => emailRegex.test(registrationForm.email),
        'email',
        'Email is invalid',
      );
    }

    return isValid;
  };

  /**
   * Function to handle the registration form submission.
   */
  const handleSubmit = async () => {
    setFormError('');
    if (!validateForm()) return;
    try {
      const loginUsername = await register({
        username: registrationForm.username,
        password: registrationForm.password,
        provider,
        providerId,
        email: registrationForm.email,
        firstName: registrationForm.firstName,
        lastName: registrationForm.lastName,
      });
      const loginUser = await getUserByUsername(loginUsername);
      setUser(loginUser);
      navigate('/home');
    } catch (error) {
      if (error instanceof Error && error.message) {
        setFormError(error.message);
      } else {
        setFormError('Error while registering');
      }
    }
  };

  return {
    registrationForm,
    registrationError,
    registrationReadOnly,
    formError,
    isSsoRegistration,
    setRegistrationForm,
    handleSubmit,
  };
};

export default useRegister;
