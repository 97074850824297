import React from 'react';
import './index.css';
import useProfilePage from '../../../hooks/useProfilePage';
import QuestionView from '../questionPage/question';

/**
 * Profile component renders a page displaying the profile of a user
 */
const ProfilePage = () => {
  const {
    profileUser,
    isEditing,
    isUser,
    newBio,
    maxBioLength,
    qlist,
    picURL,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleBioChange,
    handleStartChat,
    startChatErr,
    handlePicChange,
  } = useProfilePage();

  if (profileUser) {
    return (
      <div className='profile-page'>
        <div className='profile-header'>
          <div className='inline_images'>
            {profileUser.profilePicId ? (
              <div>
                <img src={picURL} alt={'Profile Pic'} className='inline_image pfp' />
              </div>
            ) : (
              <div>
                <img
                  src={
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuCSgl4w30I9uF5HC5icw5Ed_rgaDf-1m6LQ&s'
                  }
                  alt={'Profile Pic'}
                  className='inline_image pfp'
                />
              </div>
            )}
          </div>
          <h2 className='username'>{profileUser.username}</h2>
          {!isUser && (
            <button className='chat-button' onClick={handleStartChat}>
              Start Chat
            </button>
          )}
          <div className='start-chat-error'>{startChatErr}</div>
        </div>

        {isUser && (
          <div>
            <label htmlFor='fileInput' className='fileInputLabel'></label>
            <input
              type='file'
              id='fileInput'
              className='pfpInput'
              accept='image/png, image/gif, image/jpeg'
              onChange={handlePicChange}
            />
          </div>
        )}

        <div className='bio-section'>
          <h3>Bio</h3>
          {isEditing ? (
            <div>
              <textarea
                value={newBio}
                onChange={handleBioChange}
                maxLength={maxBioLength} // Enforce max length in HTML
                className='bio-textarea'
              />
              <p className='char-count'>
                {newBio?.length || 0}/{maxBioLength} characters
              </p>
              <div className='bio-button-group'>
                <button onClick={handleSaveClick} className='save-button'>
                  Save
                </button>
                <button onClick={handleCancelClick} className='cancel-button'>
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className='bio'>{profileUser.bio}</p>
              {isUser && (
                <button onClick={handleEditClick} className='edit-button'>
                  Edit Bio
                </button>
              )}
            </div>
          )}
        </div>

        <div className='stats-section'>
          <h3>Stats:</h3>
          <div className='stat'>
            <span className='label'>Questions Asked:&nbsp;</span>
            <span className='value'>{profileUser.questionsAsked}</span>
          </div>
          <div className='stat'>
            <span className='label'>Answers Given:&nbsp;</span>
            <span className='value'>{profileUser.answersGiven}</span>
          </div>
          <div className='stat'>
            <span className='label'>Experience:&nbsp;</span>
            <span className='value'>{profileUser.experience}</span>
          </div>
        </div>
        <div id='question_list' className='question_list'>
          <h3>Recent Questions:</h3>
          {qlist.slice(0, 3).map((q, idx) => (
            <QuestionView q={q} key={idx} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='profile-page'>
      <h3>Looking for user...</h3>
    </div>
  );
};

export default ProfilePage;
