import React from 'react';
import { Link } from 'react-router-dom';
import useRegister from '../../hooks/useRegister';
import Form from '../main/baseComponents/form';
import Input from '../main/baseComponents/input';

/**
 * Register Component contains a form that allows the user to input their registration information, which is then
 * used to create a new account through the useRegister hook.
 */
const Register = () => {
  const {
    registrationForm,
    registrationError,
    registrationReadOnly,
    formError,
    isSsoRegistration,
    setRegistrationForm,
    handleSubmit,
  } = useRegister();

  return (
    <div className='container'>
      <h2>Register</h2>
      <div className='input-form-error'>{formError}</div>
      <div className='form-container'>
        <Form>
          <Input
            title='Username'
            id='usernameInput'
            trimWhitespace={true}
            val={registrationForm.username}
            setState={value => setRegistrationForm({ ...registrationForm, username: value })}
            err={registrationError.username}
          />
          {!isSsoRegistration && (
            <>
              <Input
                title='Password'
                id='passwordInput'
                trimWhitespace={true}
                type='password'
                val={registrationForm.password}
                setState={value => setRegistrationForm({ ...registrationForm, password: value })}
                err={registrationError.password}
              />
              <Input
                title='Confirm Password'
                id='confirmPasswordInput'
                trimWhitespace={true}
                type='password'
                val={registrationForm.confirmPassword}
                setState={value =>
                  setRegistrationForm({ ...registrationForm, confirmPassword: value })
                }
                err={registrationError.confirmPassword}
              />
            </>
          )}
          <Input
            title='Email'
            id='emailInput'
            mandatory={false}
            readOnly={registrationReadOnly.email}
            trimWhitespace={true}
            val={registrationForm.email}
            setState={value => setRegistrationForm({ ...registrationForm, email: value })}
            err={registrationError.email}
          />
          <Input
            title='First Name'
            id='firstNameInput'
            mandatory={false}
            readOnly={registrationReadOnly.firstName}
            trimWhitespace={true}
            val={registrationForm.firstName}
            setState={value => setRegistrationForm({ ...registrationForm, firstName: value })}
          />
          <Input
            title='Last Name'
            id='lastNameInput'
            mandatory={false}
            readOnly={registrationReadOnly.lastName}
            trimWhitespace={true}
            val={registrationForm.lastName}
            setState={value => setRegistrationForm({ ...registrationForm, lastName: value })}
          />
          <div className='btn_indicator_container'>
            <button type='submit' className='login-button' onClick={handleSubmit}>
              Submit
            </button>
            <div className='mandatory_indicator'>* indicates mandatory fields</div>
          </div>
        </Form>
      </div>
      <span>
        Already have an account?{' '}
        <Link to={'/login'} className='login-register-link'>
          Login here
        </Link>
      </span>
      {!isSsoRegistration && (
        <>
          <Link
            to={`${process.env.REACT_APP_SERVER_URL}/auth/google`}
            className='social-login-link'>
            <img src={'google.png'} alt='Google Logo' className='social-login-image' />
            Login with Google
          </Link>
          <Link
            to={`${process.env.REACT_APP_SERVER_URL}/auth/github`}
            className='social-login-link'>
            <img src={'github.png'} alt='GitHub Logo' className='social-login-image' />
            Login with GitHub
          </Link>
        </>
      )}
    </div>
  );
};

export default Register;
