import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './layout';
import Login from './login';
import { FakeSOSocket, User } from '../types';
import LoginContext from '../contexts/LoginContext';
import UserContext from '../contexts/UserContext';
import QuestionPage from './main/questionPage';
import TagPage from './main/tagPage';
import NewQuestionPage from './main/newQuestion';
import NewAnswerPage from './main/newAnswer';
import AnswerPage from './main/answerPage';
import { getCurrentUser, getUserByUsername } from '../services/userService';
import Register from './register';
import InboxPage from './main/inboxPage';
import ChatPage from './main/chatPage';
import ProfilePage from './main/profilePage';
import DraftPage from './main/draftPage';

const AuthenticatedRoute = ({ user, children }: { user: User; children: JSX.Element }) =>
  user.username ? children : <Navigate to='/login' replace={true} />;

const AnonymousRoute = ({ user, children }: { user: User; children: JSX.Element }) =>
  !user.username ? children : <Navigate to='/home' />;

/**
 * Represents the main component of the application.
 * It manages the state for search terms and the main title.
 */
const FakeStackOverflow = ({ socket }: { socket: FakeSOSocket | null }) => {
  const [user, setUser] = useState<User>({ username: undefined });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const username = await getCurrentUser();
        if (username) {
          const loginUser = await getUserByUsername(username);
          setUser(loginUser);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  return !loading && socket ? (
    <LoginContext.Provider value={{ setUser }}>
      <UserContext.Provider value={{ user, socket }}>
        <Routes>
          {/* Routes only accessible when logged out */}
          <Route
            path='/login'
            element={
              <AnonymousRoute user={user}>
                <Login />
              </AnonymousRoute>
            }
          />
          <Route
            path='/register'
            element={
              <AnonymousRoute user={user}>
                <Register />
              </AnonymousRoute>
            }
          />

          <Route element={<Layout />}>
            {/* Routes accessible when logged in or out */}
            <Route path='/' element={<Navigate to='/home' />} />
            <Route path='/home' element={<QuestionPage />} />
            <Route path='/profile/:un' element={<ProfilePage />} />
            <Route path='tags' element={<TagPage />} />
            <Route path='/question/:qid' element={<AnswerPage />} />

            {/* Routes only accessible when logged in */}
            <Route
              path='/new/question'
              element={
                <AuthenticatedRoute user={user}>
                  <NewQuestionPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path='/new/question/:qid'
              element={
                <AuthenticatedRoute user={user}>
                  <NewQuestionPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path='/profile/'
              element={
                <AuthenticatedRoute user={user}>
                  <ProfilePage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path='/new/answer/:qid'
              element={
                <AuthenticatedRoute user={user}>
                  <NewAnswerPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path='/chat'
              element={
                <AuthenticatedRoute user={user}>
                  <InboxPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path='/drafts'
              element={
                <AuthenticatedRoute user={user}>
                  <DraftPage />
                </AuthenticatedRoute>
              }
            />
            <Route
              path='/chat/id/:cid'
              element={
                <AuthenticatedRoute user={user}>
                  <ChatPage />
                </AuthenticatedRoute>
              }
            />
          </Route>
        </Routes>
      </UserContext.Provider>
    </LoginContext.Provider>
  ) : (
    <></>
  );
};

export default FakeStackOverflow;
