import { Chat, Message } from '../types';
import { encryptSymmetric, decryptSymmetric } from './encryptionService';
import api from './config';

const CHAT_API_URL = `${process.env.REACT_APP_SERVER_URL}/chat`;

/**
 * Function to get chats by user
 *
 * @param username - Username of user to fetch chats for
 * @returns An array of chats corresponding to all chats wit hthe given user
 * @throws Error if there is an issue fetching chats.
 */
const getChatsByUser = async (username: string): Promise<Chat[]> => {
  const res = await api.get(`${CHAT_API_URL}/user/${username}`);
  if (res.status !== 200) {
    throw new Error('Error when fetching user chats.');
  }
  return res.data;
};

/**
 * Function to get chats by user
 *
 * @param id - id of chat to get
 * @returns Chat with given ID
 * @throws Error if there is an issue fetching chats.
 */
const getChatById = async (id: string): Promise<Chat> => {
  const res = await api.get(`${CHAT_API_URL}/${id}`);

  for await (const m of res.data.messages) {
    m.text = await decryptSymmetric(m.text, m.iv!, process.env.REACT_APP_GLOBAL_KEY as string);
  }

  if (res.status !== 200) {
    throw new Error('Error when fetching user chats.');
  }
  return res.data;
};

/**
 * Function to send a message in a chat
 *
 * @param cid - id of chat to send a message in
 * @param message - message to send
 * @returns Message that was sent
 * @throws Error when failure to send message
 */
const sendMessage = async (cid: string, message: Message): Promise<Message> => {
  const { ciphertext, iv } = await encryptSymmetric(
    message.text,
    process.env.REACT_APP_GLOBAL_KEY as string,
  );

  message.text = ciphertext;
  message.iv = iv;

  const reqBody = {
    id: cid,
    message,
  };
  const res = await api.post(`${CHAT_API_URL}/postMessage`, reqBody);
  if (res.status !== 200) {
    throw new Error('Error when posting message.');
  }
  return res.data;
};

/**
 * Function to create a new chat
 *
 * @param members - members to add to the newly created chat
 * @throws Error when failure to create chat
 */
const createChat = async (members: string[]): Promise<Chat> => {
  const reqBody = {
    members,
  };
  const res = await api.post(`${CHAT_API_URL}/create`, reqBody);
  if (res.status !== 200) {
    throw new Error('Error when creating chat.');
  }
  return res.data;
};

export { getChatById, getChatsByUser, sendMessage, createChat };
