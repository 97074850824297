import { ChangeEvent, useState, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../services/userService';
import useLoginContext from './useLoginContext';

/**
 * Custom hook to manage the state and logic for a header search input.
 * It handles input changes and triggers a search action on 'Enter' key press.
 *
 * @returns val - the current value of the input.
 * @returns setVal - function to update the value of the input.
 * @returns handleInputChange - function to handle changes in the input field.
 * @returns handleKeyDown - function to handle 'Enter' key press and trigger the search.
 * @returns handleLogin - function to handle the login action.
 * @returns handleLogout - function to handle the logout action.
 * @returns handleProfileClick - function to handle the profile click action.
 */
const useHeader = () => {
  const navigate = useNavigate();
  const { setUser } = useLoginContext();

  const [val, setVal] = useState<string>('');

  /**
   * Function to handle changes in the input field.
   *
   * @param e - the event object.
   */
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
  };

  /**
   * Function to handle 'Enter' key press and trigger the search.
   *
   * @param e - the event object.
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const searchParams = new URLSearchParams();
      searchParams.set('search', e.currentTarget.value);

      navigate(`/home?${searchParams.toString()}`);
    }
  };

  /**
   * Function to handle the login action.
   */
  const handleLogin = () => {
    navigate('/login');
  };

  /**
   * Function to handle the logout action.
   */
  const handleLogout = async () => {
    try {
      await logout();
      setUser({ username: undefined });
      navigate('/home');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  /**
   * Function to handle the profile click action.
   */
  const handleProfileClick = () => {
    navigate('/profile');
  };

  return {
    val,
    setVal,
    handleInputChange,
    handleKeyDown,
    handleLogin,
    handleLogout,
    handleProfileClick,
  };
};

export default useHeader;
