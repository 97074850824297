import './index.css';

import { NodeViewContent, NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import React, { ChangeEvent } from 'react';

const CodeBlockComponent: React.FC<NodeViewProps> = ({ node, updateAttributes, extension }) => {
  const { language: defaultLanguage } = node.attrs;

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    updateAttributes({ language: event.target.value });
  };

  return (
    <NodeViewWrapper className='code-block'>
      <select
        contentEditable={false}
        defaultValue={defaultLanguage}
        onChange={handleLanguageChange}>
        <option value='null'>auto</option>
        <option disabled>—</option>
        {extension.options.lowlight.listLanguages().map((lang: string) => (
          <option key={lang} value={lang}>
            {lang}
          </option>
        ))}
      </select>
      <pre>
        <NodeViewContent as='code' />
      </pre>
    </NodeViewWrapper>
  );
};

export default CodeBlockComponent;
