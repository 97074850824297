import { Socket } from 'socket.io-client';

export type FakeSOSocket = Socket<ServerToClientEvents>;

/** Interface representing the User, which contains:
 * - username - The unique username of the user.
 * - password - The password of the user (hashed). Optional field.
 * - email - The email address of the user. Optional field.
 * - firstName - The first name of the user. Optional field.
 * - lastName - The last name of the user. Optional field.
 * - bio - The bio for the user. Optional field.
 * - experience - The experience for the user. Optional field.
 * - questionsAsked - The questions asked by the user. Optional field.
 * - answersGiven - The answers given by the user. Optional field.
 */
export interface User {
  username: string | undefined;
  password?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  friends?: User[];
  bio?: string;
  experience?: number;
  questionsAsked?: number;
  answersGiven?: number;
  profilePicId?: string;
}

/**
 * Interface representing the Social Login Profile, which contains:
 * - providerId - The unique identifier for the user from the social login provider.
 * - provider - The social login provider used for authentication.
 * - firstName - The first name of the user.
 * - lastName - The last name of the user.
 * - email - The email address of the user.
 */
export interface Profile {
  providerId: string;
  provider: string;
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * Enum representing the possible ordering options for questions.
 * and their display names.
 */
export const orderTypeDisplayName = {
  newest: 'Newest',
  unanswered: 'Unanswered',
  active: 'Active',
  mostViewed: 'Most Viewed',
} as const;

/**
 * Type representing the keys of the orderTypeDisplayName object.
 * This type can be used to restrict values to the defined order types.
 */
export type OrderType = keyof typeof orderTypeDisplayName;

/**
 * Interface represents a comment.
 *
 * text - The text of the comment.
 * commentBy - Username of the author of the comment.
 * commentDateTime - Time at which the comment was created.
 */
export interface Comment {
  text: string;
  commentBy: string;
  commentDateTime: Date;
}

/**
 * Interface representing a tag associated with a question.
 *
 * @property name - The name of the tag.
 * @property description - A description of the tag.
 */
export interface Tag {
  _id?: string;
  name: string;
  description: string;
}

/**
 * Interface represents the data for a tag.
 *
 * name - The name of the tag.
 * qcnt - The number of questions associated with the tag.
 */
export interface TagData {
  name: string;
  qcnt: number;
}

/**
 * Interface representing the voting data for a question, which contains:
 * - qid - The ID of the question being voted on
 * - upVotes - An array of user IDs who upvoted the question
 * - downVotes - An array of user IDs who downvoted the question
 */
export interface VoteData {
  qid: string;
  upVotes: string[];
  downVotes: string[];
}

/**
 * Interface representing a File, which contains:
 * - _id - The unique identifier for the question/ answer the comment is associated with. Optional field.
 * - fileType - The type of the file stored
 * - fileName: - The name of the file
 *   file: string;
 * - size - Size of the file
 * - fileBy - The username of the user who uploaded the file
 * - fileDateTime - The date and time when the comment was posted.
 */

export interface UploadedFile {
  _id?: string;
  fileType: string;
  fileName: string;
  file: string;
  size: number;
  fileBy: string;
  fileDateTime: Date;
}

/**
 * Interface representing an Answer document, which contains:
 * - _id - The unique identifier for the answer. Optional field
 * - text - The content of the answer
 * - ansBy - The username of the user who wrote the answer
 * - ansDateTime - The date and time when the answer was created
 * - comments - Comments associated with the answer.
 */
export interface Answer {
  _id?: string;
  text: string;
  ansBy: string;
  ansDateTime: Date;
  comments: Comment[];
}

/**
 * Interface representing a Chat, which contains:
 * - _id - The unique identifier for the message. Optional field.
 * - members - the users who are in this chat
 * - messages - the messages which are in this chat
 */
export interface Chat {
  _id?: string;
  members: string[];
  messages: Message[];
}

/**
 * Interface representing a Message, which contains:
 * - _id - The unique identifier for the message. Optional field.
 * - text - The content of the message.
 * - iv - The initialization vector for the message.
 * - messageBy - The username of the user who created the message.
 * - messageDateTime - The date and time when the comment was posted.
 */
export interface Message {
  _id?: string;
  text: string;
  iv?: string;
  messageBy: string;
  messageDateTime: Date;
}

/**
 * Interface representing the structure of a Question object.
 *
 * - _id - The unique identifier for the question.
 * - tags - An array of tags associated with the question, each containing a name and description.
 * - answers - An array of answers to the question
 * - title - The title of the question.
 * - views - An array of usernames who viewed the question.
 * - text - The content of the question.
 * - askedBy - The username of the user who asked the question.
 * - askDateTime - The date and time when the question was asked.
 * - upVotes - An array of usernames who upvoted the question.
 * - downVotes - An array of usernames who downvoted the question.
 * - comments - Comments associated with the question.
 */
export interface Question {
  _id?: string;
  tags: Tag[];
  answers: Answer[];
  title: string;
  views: string[];
  text: string;
  askedBy: string;
  askDateTime: Date;
  upVotes: string[];
  downVotes: string[];
  comments: Comment[];
  files: UploadedFile[];
  flags: string[];
}

/**
 * Interface representing the payload for a vote update socket event.
 */
export interface VoteUpdatePayload {
  qid: string;
  upVotes: string[];
  downVotes: string[];
}

export interface AnswerUpdatePayload {
  qid: string;
  answer: Answer;
}

export interface CommentUpdatePayload {
  result: Question | Answer;
  type: 'question' | 'answer';
}

/**
 * Interface representing the possible events that the server can emit to the client.
 */
export interface ServerToClientEvents {
  questionUpdate: (question: Question) => void;
  answerUpdate: (update: AnswerUpdatePayload) => void;
  viewsUpdate: (question: Question) => void;
  voteUpdate: (vote: VoteUpdatePayload) => void;
  commentUpdate: (update: CommentUpdatePayload) => void;
  chatCreated: (chat: Chat) => void;
  messageUpdate: (message: Message) => void;
  subscribe: (cid: string) => void;
  unsubscribe: (cid: string) => void;
  fileUpdate: (question: Question) => void;
}
