import './index.css';
import { useState, useEffect } from 'react';
import useUserContext from '../../../hooks/useUserContext';
import useChatPage from '../../../hooks/useChatPage';
import { Message } from '../../../types';
import Username from '../username';

/**
 * ChatPage component renders a page displaying messages in user chats.
 * It includes field and button for sending messages.
 */
const ChatPage = () => {
  const { messages, handleSendMessage } = useChatPage();
  const { user } = useUserContext();
  const [text, setText] = useState<string>('');
  const [textErr] = useState<string>('');

  /**
   * Handler for when a user clicks the 'send message' button.
   */
  const handleSendClick = async () => {
    if (text !== '' && user.username) {
      const m: Message = {
        text,
        messageBy: user.username,
        messageDateTime: new Date(),
      };

      await handleSendMessage(m);
      setText('');
    }
  };

  // this is some magic for ensuring that the scroll stays on the bottom when a new message comes in
  useEffect(() => {
    const scroll = document.querySelector('#message_list');
    if (scroll != null) {
      scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
    }
  }, [messages]);

  // keyboard handler
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          handleSendClick();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keypress', handler);

    return () => {
      window.removeEventListener('keypress', handler);
    };
  });

  return (
    <div id='chat_container'>
      <div id='message_list' className='chat_list'>
        {messages.map((m, idx) => (
          <div className={m.messageBy === user.username ? 'chat chat-me' : 'chat'} key={idx}>
            <p className='message-text'>{m.text}</p>
            <div className='message-meta'>
              <Username user={m.messageBy}></Username>
              <h3 id='msg-datetime'>{m.messageDateTime.toString().split('T')[0]}</h3>
              <h3 id='msg-datetime'>{m.messageDateTime.toString().split('T')[1].slice(0, -1)}</h3>
            </div>
          </div>
        ))}
      </div>
      <div className='add-message'>
        <div className='input-row'>
          <textarea
            placeholder='Message'
            value={text}
            onChange={e => setText(e.target.value)}
            className='message-textarea'
          />
          <button className='send-message-button' onClick={handleSendClick}>
            Send message
          </button>
        </div>
        {textErr && <small className='error'>{textErr}</small>}
      </div>
    </div>
  );
};

export default ChatPage;
