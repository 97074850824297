import api from './config';
import { UploadedFile } from '../types';

const FILE_API_URL = `${process.env.REACT_APP_SERVER_URL}/file`;

interface AddFileRequestBody {
  id: string;
  file: UploadedFile;
}

/**
 * Adds a new file to the server.
 *
 * @param file - The file object containing the file details and data.
 * @returns The saved file document or ObjectID, as returned from the server.
 * @throws Error Throws an error if the request fails or the response status is not 200.
 */
const addFile = async (id: string, file: UploadedFile): Promise<UploadedFile> => {
  const reqBody: AddFileRequestBody = { id, file };
  const res = await api.post(`${FILE_API_URL}/addFile`, reqBody);
  if (res.status !== 200) {
    throw new Error('Error while creating a new file');
  }
  return res.data;
};

/**
 * Function to get a file by its ID.
 *
 * @param fid - The ID of the file to retrieve.
 * @throws Error if there is an issue fetching the file by ID.
 */
const getFileById = async (fid: string): Promise<UploadedFile> => {
  const res = await api.get(`${FILE_API_URL}/getFileById/${fid}`);
  if (res.status !== 200) {
    throw new Error('Error when fetching file by id');
  }
  return res.data;
};

export { addFile, getFileById };
