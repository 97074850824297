import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addQuestion } from '../services/questionService';
import { addFile } from '../services/fileService'; // Import the addFile service
import useUserContext from './useUserContext';
import { Question, UploadedFile } from '../types';
import { updateQuestion } from '../services/draftService';

const useNewQuestion = (qid?: string) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [tagNames, setTagNames] = useState<string>('');
  const [filesToBeUploaded, setFiles] = useState<UploadedFile[]>([]);
  const [flags, setFlags] = useState<string[]>([]); // New state for flags

  const [titleErr, setTitleErr] = useState<string>('');
  const [textErr, setTextErr] = useState<string>('');
  const [tagErr, setTagErr] = useState<string>('');

  function arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    // Converts to base64 string
    return window.btoa(binary);
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);
      const filePromises = fileArray.map(async file => {
        const arrayBuffer = await file.arrayBuffer();
        const base64File = arrayBufferToBase64(arrayBuffer);

        return {
          fileType: file.type,
          fileName: file.name,
          file: base64File,
          size: file.size,
          fileBy: user.username || '',
          fileDateTime: new Date(),
        };
      });
      await Promise.all(filePromises).then(setFiles);
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;

    if (!title) {
      setTitleErr('Title cannot be empty');
      isValid = false;
    } else if (title.length > 100) {
      setTitleErr('Title cannot be more than 100 characters');
      isValid = false;
    } else {
      setTitleErr('');
    }

    if (!text) {
      setTextErr('Question text cannot be empty');
      isValid = false;
    } else {
      setTextErr('');
    }

    const tagnames = tagNames.split(' ').filter(tagName => tagName.trim() !== '');
    if (tagnames.length === 0) {
      setTagErr('Should have at least 1 tag');
      isValid = false;
    } else if (tagnames.length > 5) {
      setTagErr('Cannot have more than 5 tags');
      isValid = false;
    } else {
      setTagErr('');
    }

    for (const tagName of tagnames) {
      if (tagName.length > 20) {
        setTagErr('New tag length cannot be more than 20');
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const saveQuestion = async () => {
    if (!user.username) {
      navigate('/login');
      return;
    }

    if (!validateForm()) return;

    const tagnames = tagNames.split(' ').filter(tagName => tagName.trim() !== '');
    const tags = tagnames.map(tagName => ({
      name: tagName,
      description: 'user added tag',
    }));

    // Create the question
    const question: Question = {
      title,
      text,
      tags,
      askedBy: user.username,
      askDateTime: new Date(),
      answers: [],
      upVotes: [],
      downVotes: [],
      views: [],
      comments: [],
      files: [],
      flags, // Include flags in the question
    };

    let res: Question;
    if (qid) {
      res = await updateQuestion(qid, question);
    } else {
      res = await addQuestion(question);
    }

    // Upload each file independently
    await Promise.all(
      filesToBeUploaded.map(async file => {
        if (res._id === undefined) {
          throw new Error('No QID provided.');
        }
        await addFile(res._id, file);
      }),
    );

    if (res && res._id) {
      navigate('/home');
    }
  };

  return {
    title,
    setTitle,
    text,
    setText,
    tagNames,
    setTagNames,
    titleErr,
    textErr,
    tagErr,
    saveQuestion,
    handleFileChange,
    flags,
    setFlags,
  };
};

export default useNewQuestion;
