import { Question } from '../types';
import api from './config';

const QUESTION_API_URL = `${process.env.REACT_APP_SERVER_URL}/drafts`;

/**
 * Function to get questions by filter.
 *
 * @param order - The order in which to fetch questions. Default is 'newest'.
 * @param search - The search term to filter questions. Default is an empty string.
 * @param askedBy - The user who asked a question. Default is an empty string.
 * @throws Error if there is an issue fetching or filtering questions.
 */
const getDraftsByFilter = async (
  order: string = 'newest',
  search: string = '',
  askedBy: string = '',
): Promise<Question[]> => {
  const res = await api.get(
    `${QUESTION_API_URL}/getDraft?order=${order}&search=${search}&askedBy=${askedBy}`,
  );
  if (res.status !== 200) {
    throw new Error('Error when fetching or filtering questions');
  }
  return res.data;
};

/**
 * Updates an existing question in the database.
 *
 * @param qid - The ID of the question to update.
 * @param updates - The updated fields for the question.
 * @throws Error if there is an issue updating the question.
 */
export const updateQuestion = async (
  qid: string,
  updates: Partial<Question>,
): Promise<Question> => {
  const res = await api.post(`${QUESTION_API_URL}/updateQuestion/${qid}`, updates);

  if (res.status !== 200) {
    throw new Error('Error while updating the question');
  }

  return res.data;
};

export { getDraftsByFilter };
